
/* for Loading.js */
.Loading{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%
}
.LoadingSpinner{
    color: gray;
    width: 3rem;
    height: 3rem;
    font-size: 12px;
    font-weight: medium;
}
/* end Loading.js */

/* for ShowInfoOver.js */
.showInfoOver{
    position: fixed;
    bottom: 0;
    z-index: 20;
}
/* end ShowInfoOver.js*/


/* for SimpleHeader.js */
.SimpleHeader{
    height: 70px;
}
/* end SimpleHeader.js */


/* for Unauthorize.js  */
.verticalLine{
    border-left: 5px solid tomato;
    height: 30px;
}
.UnauthorizedLink{
    text-decoration: none;
    font-size: 14px
}
/* end Unauthorized.js */