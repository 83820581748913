.displayPanel{
	font: normal normal normal 14px/17px Roboto;
	letter-spacing: 0px;
	background-color: #F3F3F3 !important;
}
.displayPanelBody{
	/* calculated -minus setting menu bar height */
	height: calc(100vh - 56px);
	padding: 0;
}
