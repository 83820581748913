
.dropdownButton {
	outline: none !important;
	border: none;
	border-radius: 20px;
	/* font: normal normal normal 10px/13px Roboto; */
	font: normal normal 500 14px/17px Roboto;
	letter-spacing: 0px;
	color: #636675;
	height: 30px;
}
.sendResponseText{
	color: #636675;
	font: normal normal normal 14px Roboto;

}
.closeChatText{
	color: #636675;
	font: normal normal normal 14px Roboto;
}
.AutoresponseRow{
	height: 80px;
	border: none;
}
.AutoresponseRowIndex{
	margin-left: 10px;
}
.AutoresponseRowTimeInputOdd{
	padding: 4px 5px;
	border: none;
	outline: none;
	width: 100px;
	box-sizing: 'border-box';
	color: #636675;
	/* font: normal normal normal 10px/13px Roboto */
	font: normal normal normal 13px/13px Roboto;
	height: 30px;
}
.AutoresponseRowTimeInputEven{
	padding: 4px 5px;
	border: 1px solid #B5B7C8;
	outline: none;
	width: 100px;
	box-sizing: border-box;
	color: #636675;
	/* font: normal normal normal 10px/13px Roboto */
	font: normal normal normal 13px/13px Roboto;
	height: 30px;
}
.AutoresponseRowDayInputEven{
	width: 100px;
	color: #636675;
	/* font: normal normal normal 10px/13px Roboto */
	font: normal normal normal 14px Roboto;
}
.AutoresponseRowDayInputOdd{
	width: 100px;
	color: #636675;
	border: 1px solid #B5B7C8;
	/* font: normal normal normal 10px/13px Roboto */
	font: normal normal normal 14px Roboto;
}
.AutoresponseRowTextareaEven{
	border: 1px solid #B5B7C8;
	outline: none;
	resize: none;
	background: 'white';
	overflow: hidden;
	box-sizing: border-box;
	color: #636675;
	/* font: normal normal normal 10px/14px Roboto */
	font: normal normal normal 14px/14px Roboto;
}
.AutoresponseRowTextareaOdd{
	border: none;
	outline: none;
	resize: none;
	background: 'white';
	overflow: hidden;
	box-sizing: border-box;
	color: #636675;
	/* font: normal normal normal 10px/14px Roboto */
	font: normal normal normal 14px/14px Roboto;
}
.AutoresponseRowCheckbox{
	width: 1.3em;
	height: 1.3em;
}