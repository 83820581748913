
.chatNavBar{
	background-color: white;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	/* height: 15vh; */
	height: 115px;
	padding: 0 15px;
}
/* @media only screen and (max-width: 1000px) {
	.chatNavBar {
		height: 140px;
	}
} */

.searchMessageBox{
	border: 1px solid #B5B7C8;
	border-radius: 24px;
}

.searchMessageBoxIcon{
	color: #B5B7C8;
	width: 18px;
	height: 18px;
	margin-left: 12px;
}

.searchMessageBoxInput{
	width: 280px;
	border: none;
	outline: none;
	background: none;
	margin: 10px;
	/* font: normal normal normal 14px/17px Roboto; suggested */
	font: normal normal normal 14px/22px Roboto;
}

.saveButton{
	border: none !important;
	outline: none !important;
	border-radius: 16px;
	padding: 4px 16px;
	color: white;
	/* margin: 12px 20px 12px 12px; */
	width: 80px;
	background-color:#0075E8
}

.cancelButton{
	border: none !important;
	outline: none !important;
	background-color:#0075E8;
	border-radius: 16px;
	padding: 4px 16px;
	color: white;
	/* margin: 12px 20px 12px 12px; */
	width: 80px;
}
.chatWaNumber{
	/* font: normal normal medium 18px/22px Roboto; */
	font: normal normal 600 18px/22px Roboto;
}