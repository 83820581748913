
.dropdownMenu{
	border: none !important;
	outline: none !important;
	background-color: #F3F3F3;
	border-radius: 16px;
	padding: 4px 8px;
	color: #636675;
	/* font: normal normal normal 12px/22px Roboto; */
}
.dropdownMenuButton{
	width: 100px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.dropdownMenuBg{
	background: #f3f3f3;
}



[data-tooltip]::after{
	position: absolute;
	/* left: 0; */
	text-align: center;
	box-sizing: border-box;
	content: attr(data-tooltip);
	color: white;
	background-color: black;
	border-radius: 5px;
	padding: 4px 10px;
	font-size: 0.9em;
	font-weight: 500;
	font-family: Roboto;
	z-index: 9999;
	visibility: hidden;
	opacity: 0;
	left: 50%;
	transform: translate(-50%,-30px);
	transition: transform 0.2s;
}
.dropdownMenu:hover span::after{
	visibility: visible;
	opacity: 1;
	transform: translate(-50%,-40px);
}
.dropdownMenu:hover span:before {
	content: "";
	width: 0px;
	height: 0px;
	position: absolute;
	border-left: 10px solid transparent;
	border-top: 10px solid black;
	left: 43%;
	top: -14px;
	transform: rotate(135deg) translate(-50%);
  }

