
.chatListSearchBox{
	background: #FFFFFF;
	border-radius: 24px;
}

.searchbarInput{
	border: none;
	outline: none;
	background: none;
	width: 100%;
	font: normal normal normal 14px/22px Roboto;
}

.searchbarIcon{
	color: #B5B7C8;
}
.dropdown-item{
	position: relative;
	/* font: normal normal normal 12px/14px Roboto; */
	/* font: normal normal normal 14px Roboto; */
}
.dropdown-menu{
	box-shadow: 0px 9px 13px #00000042 !important;
	padding: 0;
	border: 0;
	border-radius: 5px;
}
.dateFilterText{
	font-weight: 500;
}
.filterDateInput{
	width: 100%;
	border: none;
	outline: none;
	border-bottom: 1px solid black;
	background-color: transparent;
}

.dropdown2{
	position: absolute;
	left: 105%;
	/* top: 100; */
	visibility: hidden;
	transition: 0.3s;
	background-color: white;
	border-radius: 5px;
	min-width: 180px;
	box-shadow: 0px 9px 13px #00000042 !important;
	max-height: 180px;
	overflow-y: auto;
}
.dropdown-menu li:hover .dropdown2{
	visibility: visible;
	left: 105%;
}
.dropdown-menu li:hover{
	background-color: rgb(245, 245, 245);
	color: black;
	cursor: pointer;
}
.dropdown2 >ul {
	margin: 0;
	padding: 0;
	width: 100%;
	text-align: center;
	border:'none';
	border-radius: 5px;
}
.dropdown2 > ul li {
	text-decoration: none;
	width: 100%;
	display: inline-block;
	border-radius: 5px;
}
.dropdown2 >li:hover{
	background-color: rgb(245, 245, 245);

}
.filterMenuButtonImg{
	cursor: pointer;
}