
.sendMessageInputBox{
	border: none ;
	background:none;
	outline: none;
	background-color: white;
	max-height: 100px;
	min-height: 40px;
	overflow-y: auto;
	border-radius: 8px;
	resize: none;
	font: normal normal medium 14px/22px Roboto;
}
.SendMessageEditor{
	/* min-height: 13vh; */
	min-height: 90px;
	background: #F3F3F3;
	/* max-height: 20vh; */
	max-height: 120px;
	position: fixed;
	z-index: 100;
	bottom: 0;
	width: 75%;
	display: flex;
	align-items: center;
}