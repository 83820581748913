
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');


/* following two things change the look for scroll bar */
::-webkit-scrollbar{
	width: 12px;
	height: 12px;
}
::-webkit-scrollbar-thumb{
	background-image: linear-gradient(gray,lightgray);
	/* border-radius: 10px; */
	min-height: 50px;
}

.chatButton{
	border: none !important;
	outline: none !important;
	font: normal normal 500 18px/22px Roboto; 
	/* font: normal normal medium 18px/22px Roboto;  */ 
	border-radius: 5px;
	background-color: #F3F3F3;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	/* height: 2.7rem; */
	height: 48px;
}

.activeChatListBtn {
	color:white;
	background-color:#0075E8
}
.chatlistContainerMaxHeight {
	max-height: calc(100vh - 146px);
}
.chatlistContainerHeight {
	height: calc(100vh - 146px);
}
/* .scrollLeftBar {
	overflow-y: scroll;
} */

/* .scrollLeftBar > div:last-child{
	border: none;
} */

.scrollLeftBar > div:hover{
	background-color: #F3F3F3;
	cursor: pointer;
}
.customMargin{
	margin-top: 16px;
}
.toggleButtonDiv{
	display: flex;
	padding: 0;
}
