
.AutoresponseSettingsTable {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  width:100%;
  max-width: 100%;
  font-family: Roboto;
}
.AutoresponseSettings{
  /* calculated- minus setting menu bar height */
  height: calc(100vh - 56px);
  padding: 0px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px
}
.AutoresponseSettingsBodyDiv{
  /* calculated- minus parent div(AutoresponseSettings) margin,border + AutoresponseSettings heading height  */
  height: calc(100vh - 126px);
  overflow: auto;
}
.tableContainerDiv{
  border-radius: 10px;
  min-width: 1080px;
}
.AutoResponseHeader{
  height: 70px;
}
.AutoresponseSettingsButtons{
  border: none;
  outline: none;
  background: #0075E8;
  width: 6em;
}
.verticalPipeline{
  border-left: 2px solid #B5B7C8;
  height: 30px;
  margin: 0 3px;
}