
.settingsButton {
	border: none !important;
	outline: none !important;
	background: #B5B7C8 0% 0% no-repeat padding-box !important;
	border-radius: 20px;
	/* font: normal normal medium 14px/17px Roboto; */
	font: normal normal 500 14px/17px Roboto;
	letter-spacing: 0px;
	color: #222325;
	height: 30px;
}

.settingsMenu {
	align-items: center;
	/* height: 8vh; */
	height: 56px;
}

.upload-cancel {
	position: absolute;
	width: 32px;
	height: 32px;
	opacity: 0.6;
  }
  .upload-cancel:hover {
	opacity: 1;
	cursor: pointer;
  }
  .upload-cancel:before, .upload-cancel:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 20px;
	width: 2px;
	background-color: rgb(233, 61, 61);
  }
  .upload-cancel:before {
	transform: rotate(45deg);
  }
  .upload-cancel:after {
	transform: rotate(-45deg);
  }
  