
.chatMessagesContainer{
	background-color: white;
	overflow: auto !important;
	border-top: 1px solid rgb(230, 229, 229);
	/* calculated -minus settingmenu bar height+send text editor height+dropdown nav bar height  */
	height: calc(100vh - 260px);
}
.moveBottomButtonContainer{
	z-index: 10;
	position: absolute;
	/* top: 56vh; */
	bottom: 60px;
	left: 45%;
	text-align: center;
}
.moveBottomButton{
	outline: none !important;
	border: none !important;
	background-color: #222325BF !important;
	color: white;
	padding: 5px 10px;
	font: normal normal normal 14px/17px Roboto;
}
.loadMoreButton{
	outline: none !important;
	border: none !important;
	background-color: #222325BF !important;
	color: white;
	padding: 5px 10px;
	font: normal normal normal 14px/17px Roboto;
}