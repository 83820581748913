.AgentAttendance{
    padding: 0px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
.AgentAttendaceTrackerContainer{
    /* calculated-minus settingmenu bar height + AgentAttendace heading height */
    height: calc(100vh - 126px);
    overflow: auto;
}
.AgentAbsenceContainer{
    width: 80%;
    margin: 16px auto;
}
.AgentAbsenceMainDiv{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
	background:#0075E8;
    height: 70px;
}
.AgentAbsenceNameDateDiv{
    background: #5195D8;
    border-radius: 8px;
    padding: 6px 12px;
    color: white;
    font: normal normal normal 14px/17px Roboto;
}
.AgentAbsenceBodyDiv{
    border: 2px solid #b5b7c8;
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.AgentAbsenceMarkerContainer{
    width: 100%;
    background: #f3f3f3;
}
.AgentAbsenceMarkerName{
    color: #B5B7C8;
    font: normal normal medium 16px/22px Roboto;
}
.AgentPresentMarkerName{
    color: black;
    font: normal normal medium 16px/22px Roboto;
}
.AgentAbsenceMarkerCheckBox{
    width: 1.3em;
    height: 1.3em;
    cursor: pointer;
}
.AgentAbsenceMarkerCheckBoxLabel{
    width: 7em;
}
.AgentAbsenceMarkerText{
    cursor: pointer;
    font: normal normal normal 12px/14px Roboto;
}