
.agentChat{
	background: #D6EBFF;
	border-radius:15px;
	text-align: left;
	letter-spacing: 0;
	color: #636675;
	position:relative;
	font: normal normal normal 14px/17px Roboto;
	max-width: 80%;
	word-wrap: break-word;
}

.userChat{
	background: #F3F3F3;
	border-radius:15px;
	text-align: left;
	letter-spacing: 0;
	color: #636675;
	position:relative;
	font: normal normal normal 14px/17px Roboto;
	max-width: 80%;
	word-wrap: break-word;
}


.agentSpeechBubble:before {
	content: "";
	width: 0px;
	height: 0px;
	position: absolute;
	border-left: 15px solid #D6EBFF;
	border-right: 15px solid transparent;
	border-top: 15px solid #D6EBFF;
	border-bottom: 15px solid transparent;
	right: -16px;
	top: 0px;
  }
  
  
.userSpeechBubble:before {
	content: "";
	width: 0px;
	height: 0px;
	position: absolute;
	border-left: 15px solid transparent;
	border-right: 15px solid #f3f3f3;
	border-top: 15px solid #f3f3f3;
	border-bottom: 15px solid transparent;
	left: -16px;
	top: 0px;
  }
  .chatText{
	font: normal normal normal 14px/18px Roboto;
  }
  .chatTextDirection{
	  font-weight: 600;
  }