
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');


body{
	padding: 0 !important;
  margin:0 !important;
	font-family: Roboto !important;
  box-sizing: border-box !important;
  overflow: auto !important;
}
.displayPanelBg{
  background: #f3f3f3 0% 0% no-repeat padding-box !important;
}
