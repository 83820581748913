
.chatLastSender{
	font-size: 14px;
	font-weight: 500;
}
.chatCurrentStatus{
	font: normal normal 600 12px/14px Roboto;
	/* font: normal normal medium 12px/14px Roboto; */
	letter-spacing: 0.01px;
	background: #F3F3F3 0% 0% no-repeat padding-box;
	border-radius: 14px;
	padding: 4px 10px;
	
}
.chatLastText{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; 
	font: normal normal normal 14px/17px Roboto;
	color: #636675;
}

.activeChatListItem {
	background-color: #F3F3F3;
}
.chatWhatsappNumber{
	/* font: normal normal medium 18px/22px Roboto; */
	font: normal normal 600 18px/22px Roboto;
}
.chatlistItem{
	padding: 10px 12px;
}