@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


body{
	padding: 0 !important;
  margin:0 !important;
	font-family: Roboto !important;
  box-sizing: border-box !important;
  overflow: auto !important;
}
.displayPanelBg{
  background: #f3f3f3 0% 0% no-repeat padding-box !important;
}


/* following two things change the look for scroll bar */
::-webkit-scrollbar{
	width: 12px;
	height: 12px;
}
::-webkit-scrollbar-thumb{
	background-image: linear-gradient(gray,lightgray);
	/* border-radius: 10px; */
	min-height: 50px;
}

.chatButton{
	border: none !important;
	outline: none !important;
	font: normal normal 500 18px/22px Roboto; 
	/* font: normal normal medium 18px/22px Roboto;  */ 
	border-radius: 5px;
	background-color: #F3F3F3;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	/* height: 2.7rem; */
	height: 48px;
}

.activeChatListBtn {
	color:white;
	background-color:#0075E8
}
.chatlistContainerMaxHeight {
	max-height: calc(100vh - 146px);
}
.chatlistContainerHeight {
	height: calc(100vh - 146px);
}
/* .scrollLeftBar {
	overflow-y: scroll;
} */

/* .scrollLeftBar > div:last-child{
	border: none;
} */

.scrollLeftBar > div:hover{
	background-color: #F3F3F3;
	cursor: pointer;
}
.customMargin{
	margin-top: 16px;
}
.toggleButtonDiv{
	display: flex;
	padding: 0;
}


.chatLastSender{
	font-size: 14px;
	font-weight: 500;
}
.chatCurrentStatus{
	font: normal normal 600 12px/14px Roboto;
	/* font: normal normal medium 12px/14px Roboto; */
	letter-spacing: 0.01px;
	background: #F3F3F3 0% 0% no-repeat padding-box;
	border-radius: 14px;
	padding: 4px 10px;
	
}
.chatLastText{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; 
	font: normal normal normal 14px/17px Roboto;
	color: #636675;
}

.activeChatListItem {
	background-color: #F3F3F3;
}
.chatWhatsappNumber{
	/* font: normal normal medium 18px/22px Roboto; */
	font: normal normal 600 18px/22px Roboto;
}
.chatlistItem{
	padding: 10px 12px;
}

.chatListSearchBox{
	background: #FFFFFF;
	border-radius: 24px;
}

.searchbarInput{
	border: none;
	outline: none;
	background: none;
	width: 100%;
	font: normal normal normal 14px/22px Roboto;
}

.searchbarIcon{
	color: #B5B7C8;
}
.dropdown-item{
	position: relative;
	/* font: normal normal normal 12px/14px Roboto; */
	/* font: normal normal normal 14px Roboto; */
}
.dropdown-menu{
	box-shadow: 0px 9px 13px #00000042 !important;
	padding: 0;
	border: 0;
	border-radius: 5px;
}
.dateFilterText{
	font-weight: 500;
}
.filterDateInput{
	width: 100%;
	border: none;
	outline: none;
	border-bottom: 1px solid black;
	background-color: transparent;
}

.dropdown2{
	position: absolute;
	left: 105%;
	/* top: 100; */
	visibility: hidden;
	transition: 0.3s;
	background-color: white;
	border-radius: 5px;
	min-width: 180px;
	box-shadow: 0px 9px 13px #00000042 !important;
	max-height: 180px;
	overflow-y: auto;
}
.dropdown-menu li:hover .dropdown2{
	visibility: visible;
	left: 105%;
}
.dropdown-menu li:hover{
	background-color: rgb(245, 245, 245);
	color: black;
	cursor: pointer;
}
.dropdown2 >ul {
	margin: 0;
	padding: 0;
	width: 100%;
	text-align: center;
	border:'none';
	border-radius: 5px;
}
.dropdown2 > ul li {
	text-decoration: none;
	width: 100%;
	display: inline-block;
	border-radius: 5px;
}
.dropdown2 >li:hover{
	background-color: rgb(245, 245, 245);

}
.filterMenuButtonImg{
	cursor: pointer;
}

/* for Loading.js */
.Loading{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%
}
.LoadingSpinner{
    color: gray;
    width: 3rem;
    height: 3rem;
    font-size: 12px;
    font-weight: medium;
}
/* end Loading.js */

/* for ShowInfoOver.js */
.showInfoOver{
    position: fixed;
    bottom: 0;
    z-index: 20;
}
/* end ShowInfoOver.js*/


/* for SimpleHeader.js */
.SimpleHeader{
    height: 70px;
}
/* end SimpleHeader.js */


/* for Unauthorize.js  */
.verticalLine{
    border-left: 5px solid tomato;
    height: 30px;
}
.UnauthorizedLink{
    text-decoration: none;
    font-size: 14px
}
/* end Unauthorized.js */
.displayPanel{
	font: normal normal normal 14px/17px Roboto;
	letter-spacing: 0px;
	background-color: #F3F3F3 !important;
}
.displayPanelBody{
	/* calculated -minus setting menu bar height */
	height: calc(100vh - 56px);
	padding: 0;
}


.settingsButton {
	border: none !important;
	outline: none !important;
	background: #B5B7C8 0% 0% no-repeat padding-box !important;
	border-radius: 20px;
	/* font: normal normal medium 14px/17px Roboto; */
	font: normal normal 500 14px/17px Roboto;
	letter-spacing: 0px;
	color: #222325;
	height: 30px;
}

.settingsMenu {
	align-items: center;
	/* height: 8vh; */
	height: 56px;
}

.upload-cancel {
	position: absolute;
	width: 32px;
	height: 32px;
	opacity: 0.6;
  }
  .upload-cancel:hover {
	opacity: 1;
	cursor: pointer;
  }
  .upload-cancel:before, .upload-cancel:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 20px;
	width: 2px;
	background-color: rgb(233, 61, 61);
  }
  .upload-cancel:before {
	transform: rotate(45deg);
  }
  .upload-cancel:after {
	transform: rotate(-45deg);
  }
  

.agentHomeHeading {
    font: normal normal normal 21px/25px Roboto;
    letter-spacing: 0px;
    color: #636675;
}


.AutoresponseSettingsTable {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  width:100%;
  max-width: 100%;
  font-family: Roboto;
}
.AutoresponseSettings{
  /* calculated- minus setting menu bar height */
  height: calc(100vh - 56px);
  padding: 0px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px
}
.AutoresponseSettingsBodyDiv{
  /* calculated- minus parent div(AutoresponseSettings) margin,border + AutoresponseSettings heading height  */
  height: calc(100vh - 126px);
  overflow: auto;
}
.tableContainerDiv{
  border-radius: 10px;
  min-width: 1080px;
}
.AutoResponseHeader{
  height: 70px;
}
.AutoresponseSettingsButtons{
  border: none;
  outline: none;
  background: #0075E8;
  width: 6em;
}
.verticalPipeline{
  border-left: 2px solid #B5B7C8;
  height: 30px;
  margin: 0 3px;
}

.dropdownButton {
	outline: none !important;
	border: none;
	border-radius: 20px;
	/* font: normal normal normal 10px/13px Roboto; */
	font: normal normal 500 14px/17px Roboto;
	letter-spacing: 0px;
	color: #636675;
	height: 30px;
}
.sendResponseText{
	color: #636675;
	font: normal normal normal 14px Roboto;

}
.closeChatText{
	color: #636675;
	font: normal normal normal 14px Roboto;
}
.AutoresponseRow{
	height: 80px;
	border: none;
}
.AutoresponseRowIndex{
	margin-left: 10px;
}
.AutoresponseRowTimeInputOdd{
	padding: 4px 5px;
	border: none;
	outline: none;
	width: 100px;
	box-sizing: 'border-box';
	color: #636675;
	/* font: normal normal normal 10px/13px Roboto */
	font: normal normal normal 13px/13px Roboto;
	height: 30px;
}
.AutoresponseRowTimeInputEven{
	padding: 4px 5px;
	border: 1px solid #B5B7C8;
	outline: none;
	width: 100px;
	box-sizing: border-box;
	color: #636675;
	/* font: normal normal normal 10px/13px Roboto */
	font: normal normal normal 13px/13px Roboto;
	height: 30px;
}
.AutoresponseRowDayInputEven{
	width: 100px;
	color: #636675;
	/* font: normal normal normal 10px/13px Roboto */
	font: normal normal normal 14px Roboto;
}
.AutoresponseRowDayInputOdd{
	width: 100px;
	color: #636675;
	border: 1px solid #B5B7C8;
	/* font: normal normal normal 10px/13px Roboto */
	font: normal normal normal 14px Roboto;
}
.AutoresponseRowTextareaEven{
	border: 1px solid #B5B7C8;
	outline: none;
	resize: none;
	background: 'white';
	overflow: hidden;
	box-sizing: border-box;
	color: #636675;
	/* font: normal normal normal 10px/14px Roboto */
	font: normal normal normal 14px/14px Roboto;
}
.AutoresponseRowTextareaOdd{
	border: none;
	outline: none;
	resize: none;
	background: 'white';
	overflow: hidden;
	box-sizing: border-box;
	color: #636675;
	/* font: normal normal normal 10px/14px Roboto */
	font: normal normal normal 14px/14px Roboto;
}
.AutoresponseRowCheckbox{
	width: 1.3em;
	height: 1.3em;
}
.AgentAttendance{
    padding: 0px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
.AgentAttendaceTrackerContainer{
    /* calculated-minus settingmenu bar height + AgentAttendace heading height */
    height: calc(100vh - 126px);
    overflow: auto;
}
.AgentAbsenceContainer{
    width: 80%;
    margin: 16px auto;
}
.AgentAbsenceMainDiv{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
	background:#0075E8;
    height: 70px;
}
.AgentAbsenceNameDateDiv{
    background: #5195D8;
    border-radius: 8px;
    padding: 6px 12px;
    color: white;
    font: normal normal normal 14px/17px Roboto;
}
.AgentAbsenceBodyDiv{
    border: 2px solid #b5b7c8;
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.AgentAbsenceMarkerContainer{
    width: 100%;
    background: #f3f3f3;
}
.AgentAbsenceMarkerName{
    color: #B5B7C8;
    font: normal normal medium 16px/22px Roboto;
}
.AgentPresentMarkerName{
    color: black;
    font: normal normal medium 16px/22px Roboto;
}
.AgentAbsenceMarkerCheckBox{
    width: 1.3em;
    height: 1.3em;
    cursor: pointer;
}
.AgentAbsenceMarkerCheckBoxLabel{
    width: 7em;
}
.AgentAbsenceMarkerText{
    cursor: pointer;
    font: normal normal normal 12px/14px Roboto;
}

.chatMessagesContainer{
	background-color: white;
	overflow: auto !important;
	border-top: 1px solid rgb(230, 229, 229);
	/* calculated -minus settingmenu bar height+send text editor height+dropdown nav bar height  */
	height: calc(100vh - 260px);
}
.moveBottomButtonContainer{
	z-index: 10;
	position: absolute;
	/* top: 56vh; */
	bottom: 60px;
	left: 45%;
	text-align: center;
}
.moveBottomButton{
	outline: none !important;
	border: none !important;
	background-color: #222325BF !important;
	color: white;
	padding: 5px 10px;
	font: normal normal normal 14px/17px Roboto;
}
.loadMoreButton{
	outline: none !important;
	border: none !important;
	background-color: #222325BF !important;
	color: white;
	padding: 5px 10px;
	font: normal normal normal 14px/17px Roboto;
}

.agentChat{
	background: #D6EBFF;
	border-radius:15px;
	text-align: left;
	letter-spacing: 0;
	color: #636675;
	position:relative;
	font: normal normal normal 14px/17px Roboto;
	max-width: 80%;
	word-wrap: break-word;
}

.userChat{
	background: #F3F3F3;
	border-radius:15px;
	text-align: left;
	letter-spacing: 0;
	color: #636675;
	position:relative;
	font: normal normal normal 14px/17px Roboto;
	max-width: 80%;
	word-wrap: break-word;
}


.agentSpeechBubble:before {
	content: "";
	width: 0px;
	height: 0px;
	position: absolute;
	border-left: 15px solid #D6EBFF;
	border-right: 15px solid transparent;
	border-top: 15px solid #D6EBFF;
	border-bottom: 15px solid transparent;
	right: -16px;
	top: 0px;
  }
  
  
.userSpeechBubble:before {
	content: "";
	width: 0px;
	height: 0px;
	position: absolute;
	border-left: 15px solid transparent;
	border-right: 15px solid #f3f3f3;
	border-top: 15px solid #f3f3f3;
	border-bottom: 15px solid transparent;
	left: -16px;
	top: 0px;
  }
  .chatText{
	font: normal normal normal 14px/18px Roboto;
  }
  .chatTextDirection{
	  font-weight: 600;
  }

.chatNavBar{
	background-color: white;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	/* height: 15vh; */
	height: 115px;
	padding: 0 15px;
}
/* @media only screen and (max-width: 1000px) {
	.chatNavBar {
		height: 140px;
	}
} */

.searchMessageBox{
	border: 1px solid #B5B7C8;
	border-radius: 24px;
}

.searchMessageBoxIcon{
	color: #B5B7C8;
	width: 18px;
	height: 18px;
	margin-left: 12px;
}

.searchMessageBoxInput{
	width: 280px;
	border: none;
	outline: none;
	background: none;
	margin: 10px;
	/* font: normal normal normal 14px/17px Roboto; suggested */
	font: normal normal normal 14px/22px Roboto;
}

.saveButton{
	border: none !important;
	outline: none !important;
	border-radius: 16px;
	padding: 4px 16px;
	color: white;
	/* margin: 12px 20px 12px 12px; */
	width: 80px;
	background-color:#0075E8
}

.cancelButton{
	border: none !important;
	outline: none !important;
	background-color:#0075E8;
	border-radius: 16px;
	padding: 4px 16px;
	color: white;
	/* margin: 12px 20px 12px 12px; */
	width: 80px;
}
.chatWaNumber{
	/* font: normal normal medium 18px/22px Roboto; */
	font: normal normal 600 18px/22px Roboto;
}

.dropdownMenu{
	border: none !important;
	outline: none !important;
	background-color: #F3F3F3;
	border-radius: 16px;
	padding: 4px 8px;
	color: #636675;
	/* font: normal normal normal 12px/22px Roboto; */
}
.dropdownMenuButton{
	width: 100px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.dropdownMenuBg{
	background: #f3f3f3;
}



[data-tooltip]::after{
	position: absolute;
	/* left: 0; */
	text-align: center;
	box-sizing: border-box;
	content: attr(data-tooltip);
	color: white;
	background-color: black;
	border-radius: 5px;
	padding: 4px 10px;
	font-size: 0.9em;
	font-weight: 500;
	font-family: Roboto;
	z-index: 9999;
	visibility: hidden;
	opacity: 0;
	left: 50%;
	transform: translate(-50%,-30px);
	transition: transform 0.2s;
}
.dropdownMenu:hover span::after{
	visibility: visible;
	opacity: 1;
	transform: translate(-50%,-40px);
}
.dropdownMenu:hover span:before {
	content: "";
	width: 0px;
	height: 0px;
	position: absolute;
	border-left: 10px solid transparent;
	border-top: 10px solid black;
	left: 43%;
	top: -14px;
	transform: rotate(135deg) translate(-50%);
  }



.sendMessageInputBox{
	border: none ;
	background:none;
	outline: none;
	background-color: white;
	max-height: 100px;
	min-height: 40px;
	overflow-y: auto;
	border-radius: 8px;
	resize: none;
	font: normal normal medium 14px/22px Roboto;
}
.SendMessageEditor{
	/* min-height: 13vh; */
	min-height: 90px;
	background: #F3F3F3;
	/* max-height: 20vh; */
	max-height: 120px;
	position: fixed;
	z-index: 100;
	bottom: 0;
	width: 75%;
	display: flex;
	align-items: center;
}
/* Absolute Center Spinner */
.spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.spinner:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
    background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.spinner:not(:required) {
    /* hide "spinner..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.spinner:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    animation: spinner 150ms infinite linear;
    border-radius: 0.5em;
    box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */
@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
